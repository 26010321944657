<template>
  <div>
    <tiptap-vuetify v-model="content" :extensions="extensions" />
  </div>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  CodeBlock
} from 'tiptap-vuetify'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        CodeBlock,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      // starting editor's content
      content: this.value,
      firstLoad: true
    }
  },
  watch: {
    value(v) {
      // this.content = v
      if (this.firstLoad) {
        let el = document.querySelector('.ProseMirror')
        el.innerHTML = this.value
        this.firstLoad = false
      }
    },
    content(value) {
      this.$emit('input', value)
    }
  },
  mounted() {}
}
</script>

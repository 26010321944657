<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title
        class="headline font-weight-bold"
        v-if="$route.name === 'settings.dashboard'"
        >Settings</v-toolbar-title
      >

      <v-toolbar-title
        class="breadcrumbs d-flex align-center"
        v-if="$route.name !== 'settings.dashboard'"
      >
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'settings' }"
          >Settings</router-link
        >
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon>
        <img src="@/assets/icons/search.svg" alt height="17.7px" />
      </v-btn>
    </v-app-bar>
    <router-view class="ml-4" />
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
export default {
  name: 'SettingsPage',

  mixins: [ControlsMixin],
  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline
      }
    }
  },
  components: {
    AppBarNavIcon
  },
  watch: {
    $route() {}
  }
}
</script>
